.filterActions {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 200px 200px;
  grid-auto-columns: 1fr;
  max-width: 500px;
  margin-bottom: 20px;
  grid-gap: 20px;
  justify-content: center;
}
