.dashboardCard {
  width: 470px;
  height: 580px;
  @media screen and (max-width: 1200px) {
    width: 100%;
    height: 670px;
    margin-bottom: 1rem;
  }

  .menuContainer {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 100px;
    padding: 40px;
    @media screen and (max-width: 1200px) {
      padding: 0;
      margin-right: 1rem;
      margin-left: 1rem;
      margin-top: 2rem;
    }
    grid-gap: 20px;
    justify-content: center;
    align-items: center;
    height: fit-content;

    .menuItem {
      background-color: #fff;
      border: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: rgba(0, 0, 0, 0.847);
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      position: relative;
      height: 100%;
      text-decoration: none;

      .menuIcon {
        margin: 0px 40px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;

        width: 0;
        height: 100%;
        transition: all 0.3s ease;
      }
      
      &:hover:not(:last-child) {
        cursor: pointer;
        &:before {
          width: 100%;
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}