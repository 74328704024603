.configurationsCard {
  overflow-y: scroll;
  max-height: calc(100vh - 430px);
  .title {
    font-size: 14;
  }

  .avatar {
    background-color: #BD8D2B;
  }


}
.configurationEditIcon {
  display: flex !important;
  justify-content: right !important;
}