.app {
  width: 100%;
  background-color: #F7F6FA;

  &.landing {
    background: url(./images/GARO_20-10-21_MGL8667.png) no-repeat center center;
    background-size: cover;
    width: 100%;
  }



  .dashboardContainer {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 5rem;
    margin-bottom: 5rem;
    justify-content: center;

    @media screen and (max-width: 1200px) {
      margin: 2rem;
      flex-direction: column;
    }
  }
}