.fieldContainer{
  margin-left: 2rem;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  .fieldEditContiner {

  display: flex;
  flex-direction: row;
  max-width: 40rem;

      .fieldInput{
    flex-grow: 1;
  }
  .booleanField {
    display: inline-flex;
    align-items: center;
    margin-left: -12px;
    margin-right: 16px;
    vertical-align: middle;
    flex-grow: 1;
  }
  .booleanCustomLabel {
    line-height: 1.5;
    &.disabled {
      color: rgba(0,0,0,0.38);
    }
  }
  }


}
