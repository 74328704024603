.installerIcon {
  width: 25px;
  height: 25px;
  margin: 10px;
  background-image: url('../../../../images/installer.png');
  background-repeat: no-repeat;
  background-size: 25px 25px;
}

.ownerIcon {
  width: 25px;
  height: 25px;
  margin: 10px;
  background-image: url('../../../../images/owner.png');
  background-repeat: no-repeat;
  background-size: 25px 25px;
}