.spinner {
  margin: 0px auto;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 12px;
  height: 12px;
  background-color: #BD8D2B;
  margin: 0 4px;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  -moz-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  -moz-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  -moz-animation-delay:  -0.16s;
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
      -webkit-transform: scale(0);
      transform: scale(0);
  }
  40% {
      -webkit-transform: scale(1);
      transform: scale(1);
  }
}
