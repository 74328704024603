  .container{
    margin-right: 70px;
    height: 80px;
  }
  .subContainer {
    width: '100%';
    flex-direction: column;

  }
  .label {
    flex-direction: column;
    align-items: center;
    width: 50px;
  }

  .lineStyle {
    width: 100%;
    height: 30px;
    margin-bottom: -7px;
    margin-top: -3px;
  }

  .phaseRotationConnector {
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }