body {
  margin: 0;
  font-family: Futura;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  height: 100vh;
  // width: 100vw;
}

* {
  font-family: Futura;

}

@font-face {
  font-family: 'Futura';
  src: local('Futura'), url(./fonts/Futura_Std_Book.otf) format('opentype');
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

input {
  font-size: 16px;
  line-height: 19px;
}