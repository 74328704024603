.documentationCard {

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
  .headerButton {
    background-color: #fff;
    border: none;
    cursor: pointer;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
  }

  .menuContainer {
    padding: 40px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 800px;
    width: 1300px;
    column-gap: 25px;

    @media screen and (max-width: 1300px) {
      width: calc(100% - 1rem);
      height: 100%;
      padding: 0px;
      margin-left: auto;
      margin-right: auto;
    }

    .itemsContainer {
      h1 {
        margin-bottom: 0.7rem;
      }

      .menuItem {
        background-color: #fff;
        border: none;
        display: flex;
        flex-direction: row;
        align-items: center;
      
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        position: relative;
        margin-bottom: 20px;
        height: 80px;
        @media screen and (max-width: 1300px) {
          margin-right: 0px;
        }
        .menuIcon {
          margin: 0px 40px;
        }

        .downloadIcon {
          margin-left: auto;
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;

          width: 0;
          height: 100%;
          transition: all 0.3s ease;
        }

        &:hover {
          cursor: pointer;

          &:before {
            width: 100%;
            background: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }

  }

  .itemButtonText {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #7F6F3D;
    margin-right: 15px;
    width: 180px;
  }
}