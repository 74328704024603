.filterActions {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr 150px;
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
  max-width: 600px;
  width: 100%;


  .searchField {
    background: #fffdfd;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 4px;
    height: 48px;
    margin-right: 1rem;
    @media screen and (max-width: 1200px) {
      margin-right: 0;
    }
    padding-left: 30px;
    background-image: url('../../../images/search.svg');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: 8px 15px;
    font-size: 1.5rem;
    flex-grow: 1;
    width: 100%;


  }
}

.chargingStationTable {
  margin-top: 3rem;
  .actionColumn {
    display: flex;
    flex-direction: row;
  }
  .icon {
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-size: 25px 25px;
    margin-left: 10px;
    margin-right: 10px;

    &.edit {
      cursor: pointer;
      background-image: url('../../../images/edit.svg');
    }

    &.monitoring {
      cursor: pointer;
      background-image: url('../../../images/monitoring.svg');
    }

    &.meshMaster {
      background-image: url('../../../images/mesh_master.svg');
    }
  }
}

.loader {
  margin-top: 10rem;
}
