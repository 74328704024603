.downloadApp {
  background-color: transparent;
  justify-content: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  border: none;
  color: rgba(0, 0, 0, 0.847);
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  position: relative;
  height: 100%;
  text-decoration: none;


  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    @media screen and (max-width: 1200px) {
      align-self: center;
    }
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
      row-gap: 20px;
    }

    .downloadAppButton {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: transparent;
      border: 1px solid #cccccc;
      border-radius: 10px;
      margin-right: 20px;
      width: 190px;
      height: 70px;
      cursor: pointer;
      padding: 0;
      text-decoration: none;
      color: rgba(0, 0, 0, 0.847);
      @media screen and (max-width: 1200px) {
        align-self: center;
        margin-right: 0px;
      }

      .picture {
        margin: 0px 10px;
      }

      .textContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;

        .getItOn {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
        }

        .store {
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }
}