  .banner_text {
    color: #BD8D2B;
  }
  
  .banner {
    background-color: #fafafa;
    border: 2px dashed #ddd;
    border-radius: 10px;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
  }
  
  .input {
    display: none;
  }