.navbar {
  display: flex;
  justify-content: space-between;
  background:rgba(0, 0, 0, 0.5);
  color: #fff;
  align-items: center;



  .innerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1800px;
    width: calc(100% - 5rem);

    > .brandContainer {
      margin-top: 1rem;
      margin-bottom: 1rem;
      margin-left: auto;
      display: flex;
      align-items: center;
      *:nth-child(n):not(:last-child) {
        &:after {
          content: '|';
          margin-left: 1rem;
          margin-right: 1rem;
          color: #aaa8b1;
        }
      }
      .globeIcon {
        width: 25px;
        height: 25px;
        background-image: url('../../images/globe.png');
        background-repeat: no-repeat;
        background-size: 25px 25px;
        margin-right: 10px;
      }
    }
  
    a {
      font-size: 16px;
      font-weight: 700;
      display: flex;
      align-items: center;
  
      cursor: auto;
      color: inherit;
      text-align: left;
      text-decoration: none;
    }
    .logo {
      background-image: url(../../images/Logo.png);
      width: 66px;
      height: 25px;
      background-repeat: no-repeat;
      background-size: 66px 25px;
      margin-right: 40px;
    }
  }


}

.userCard {
  position: absolute;
  top: 100px;
  width: 480px;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
  padding: 2rem;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
