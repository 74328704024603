.regionCard {
  width: 480px;

  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  --regionWidth: 190px;
  --regionHeight: 72px;


  @media screen and (max-width: 1200px) {
    margin-left: auto;
    margin-right: auto;
  }
  &.languangeCard {
    margin-top: 0;
    height: fit-content;
  }

  .logo {
    margin-top: 40px;
    background-image: url('../../images/Logo.png');
    width: 104px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: 104px 40px;
  }

  .regionTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .regionContainer {
    display: grid;
    grid-template-columns: repeat(2, 190px);
    grid-gap: 10px;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: 40px;
    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(1, 190px);
    }

    .region {
      border: 1px solid #f7f6fa;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      position: relative;

      padding: 20px;

      &.selected {
        background-color: #333333;
        color: #fff;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;

        width: 0;
        height: var(--regionHeight);
        transition: all 0.3s ease;
      }
      &:hover {
        &:before {
          width: calc(var(--regionWidth) - 6px);
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
