.userDetails {
  display: flex;
  flex-direction: column;
  .userDetailsRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
  }


  .MuiInputBase-root.Mui-disabled, .MuiFormLabel-root.Mui-disabled {
    color: rgba(0, 0, 0, 1);
    cursor: default;
    }
    .field {
      margin-bottom: 10px;
      min-width: 20rem;
      width: fit-content;
    }
}
