  .loginCard {
    width: 470px;
    height: 580px;
    @media screen and (max-width: 1200px) {
      width: 100%;
    }

    .loginForm {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      height: 100%;
      @media screen and (max-width: 1200px) {
        margin-right: 1rem;
        margin-left: 1rem;
        margin-top: 2rem;
      }
  

      .logo {
        margin-top: 38px;
        margin-bottom: 138px;
        background-image: url(../../../images/Logo.png);
        width: 288px;
        height: 111px;
        background-repeat: no-repeat;
        background-size: contain;
        @media screen and (max-width: 1200px) {
          width: 144px;
          height: 60px;
        }
      }

      .loginInput {
        width: 300px;
        @media screen and (max-width: 1200px) {
          width: 100%;
        }
        margin-bottom: 12px;
      }

      .submitButton {
        width: 300px;
        @media screen and (max-width: 1200px) {
          width: 100%;
          margin-right: 1rem;
          margin-left: 1rem;
        }
        margin-top: 40px;
      }

      .loggingIn {
        background-color: rgba(0, 0, 0, 0.12);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: +1;
        display: flex;
        align-items: center;
      }
    }
  }
