.dlmText {
  font-family: monospace;
  font-size: 2rem;
}

.container {
  width: 1000px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
}